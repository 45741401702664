import { VatQuotesSettings } from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  quotes: { [propertyId: number]: VatQuotesSettings };
  allInclusiveTaxDocument: { [propertyId: number]: boolean };
}

export const initialState: State = {
  isLoading: false,
  error: null,
  quotes: null,
  allInclusiveTaxDocument: null,
};
