import {
  VatQuotesSettingsResponse,
  VatQuotesSettingsUpdate,
} from '@app/models';
import { Action } from '@ngrx/store';

import { Hook } from '../../helpers';

export enum ActionTypes {
  LOAD_REQUEST = '[Vat Quote Settings] Load Request',
  LOAD_FAILURE = '[Vat Quote Settings] Load Failure',
  LOAD_SUCCESS = '[Vat Quote Settings] Load Success',

  UPDATE_REQUEST = '[Vat Quote Settings] Update Request',
  UPDATE_FAILURE = '[Vat Quote Settings] Update Failure',
  UPDATE_SUCCESS = '[Vat Quote Settings] Update Success',

  RESET_BILLS_REQUEST = '[Vat Quote Settings] Reset Bills Request',
  RESET_BILLS_FAILURE = '[Vat Quote Settings] Reset Bills Failure',
  RESET_BILLS_SUCCESS = '[Vat Quote Settings] Reset Bills Success',

  RESET_STATE = '[Vat Quote Settings] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId?: number[] }) {}
}
export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { vatQuotesData: VatQuotesSettingsResponse }) {}
}
export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(public payload: { request: VatQuotesSettingsUpdate }) {}
}
export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class ResetBillsRequestAction implements Action {
  readonly type = ActionTypes.RESET_BILLS_REQUEST;
  constructor(
    public payload: {
      request: { propertyIDS: number[] };
      onSuccess?: Hook;
      onFailure?: Hook;
    },
  ) {}
}
export class ResetBillsFailureAction implements Action {
  readonly type = ActionTypes.RESET_BILLS_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class ResetBillsSuccessAction implements Action {
  readonly type = ActionTypes.RESET_BILLS_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadSuccessAction
  | LoadFailureAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | ResetBillsRequestAction
  | ResetBillsSuccessAction
  | ResetBillsFailureAction
  | ResetSuccessAction;
