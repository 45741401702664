import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { VatQuotesSettingsResponse } from '@app/models';
import { VatQuoteService } from '@app/services/vat-quote-settings.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { effectHooks } from '../../helpers';

import * as featureActions from './actions';

@Injectable()
export class VatQuoteStoreEffects {
  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    private store: Store<{ core: ICoreState }>,
    private notifications: NotificationService,
    private vatQuoteSettingsService: VatQuoteService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.propertyId;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          return this.vatQuoteSettingsService.load(propertiesIds).pipe(
            map(
              ({ data }: IResponseSuccess<VatQuotesSettingsResponse>) =>
                new featureActions.LoadSuccessAction({
                  vatQuotesData: data,
                }),
            ),
            catchError((error) =>
              of(new featureActions.LoadFailureAction({ error })),
            ),
          );
        },
      ),
    ),
  );

  updateRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        return this.vatQuoteSettingsService.update(action.payload.request).pipe(
          map(({ meta }: IResponseSuccess) => {
            if (meta.warnings) {
              meta.warnings.forEach((warning) =>
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: warning,
                  type: 'warning',
                }),
              );
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('vat_quotes'),
                  },
                ),
                type: 'success',
              });
            }

            return new featureActions.UpdateSuccessAction();
          }),
          catchError((error) => {
            this.notifications.push({
              title: this.translate.instant('error'),
              content: this.translate.instant('notifications.update_failure', {
                param: this.translate.instant('vat_quotes'),
              }),
              type: 'error',
            });
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  resetBillsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.ResetBillsRequestAction>(
        featureActions.ActionTypes.RESET_BILLS_REQUEST,
      ),
      switchMap(
        ({
          payload: {
            request: { propertyIDS },
            ...hooks
          },
        }: featureActions.ResetBillsRequestAction) => {
          return this.vatQuoteSettingsService
            .resetBillsVatQuotes(propertyIDS)
            .pipe(
              effectHooks(hooks),
              map(() => {
                this.notifications.success(
                  this.translate.instant('notifications.generic_success'),
                );
                return new featureActions.ResetBillsSuccessAction();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  new featureActions.ResetBillsFailureAction({ error }),
                );
              }),
            );
        },
      ),
    ),
  );
}
