import { mapValues } from 'lodash';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        quotes: null,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        quotes: mapValues(
          action.payload.vatQuotesData,
          (value) => value.vat_quotes,
        ),
        allInclusiveTaxDocument: mapValues(
          action.payload.vatQuotesData,
          (value) => !!value.enable_all_inclusive_tax_document,
        ),
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
