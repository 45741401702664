import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectVatQuotesState = createFeatureSelector<State>('vatQuotes');

export const selectVatQuotesData = createSelector(
  selectVatQuotesState,
  (state) => state.quotes,
);

export const selectVatQuotesError: MemoizedSelector<
  object,
  any
> = createSelector(selectVatQuotesState, getError);

export const selectVatQuotesLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectVatQuotesState, getIsLoading);

export const selectAllInclusiveTaxDocument = createSelector(
  selectVatQuotesState,
  (state: State) => state.allInclusiveTaxDocument,
);
